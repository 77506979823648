import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import PaypalButton from '../components/paypalButton';
import Img from "gatsby-image"
import {red} from "@material-ui/core/colors";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
//title image description price buy-Value
const Item = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const { title, id, desc, price, salePrice, imgUrl, hidden } = props;

  return (
    hidden ? (null) : (
    <div>
      <Card className={classes.card}>
      <CardContent>
      {/*<Img fluid={data.placeholderImage.childImageSharp.fluid} />*/}
        <Img fluid={imgUrl} />
        <div className="card-bottom">
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
          <br/>
        <Typography variant="body2" component="p">
          {desc}
        </Typography>
          <br/>
        <Typography className="item-price" variant="subtitle1" component="p">
          <center>{price}</center>
        </Typography>
        <Typography variant="subtitle1" component="p">
          <center>{salePrice}</center>
        </Typography>
        </div>
      </CardContent>
      <CardActions>
        <PaypalButton id={id}/>
      </CardActions>

    </Card>
    </div>
    )
  )
}

export default Item;