import React from "react"

const PaypalButton = props => {
  const { id } = props;

  return (
    <form class="addtocart" target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post" style={{width: "100%"}}>
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value={id} />
      <input type="submit" className="submit-button" value="Purchase"/>
      {/*<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1"/>*/}
    </form>
  )
};

export default PaypalButton