/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useStaticQuery, graphql, Link} from "gatsby"
import AppBar from '@material-ui/core/AppBar';
import Helmet from 'react-helmet';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import "./all.scss"
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DockIcon from "@material-ui/icons/Dock";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from '@material-ui/core/Divider';
import StoreIcon from '@material-ui/icons/Store';
import HotelIcon from '@material-ui/icons/Hotel';
import AddIcon from '@material-ui/icons/Add';
import PaymentIcon from '@material-ui/icons/Payment';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Hidden from '@material-ui/core/Hidden';
import {ThemeProvider} from '@material-ui/styles';
import {clearTheme} from "./theme";
import Img from "gatsby-image"
import logo from "./logo";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // appBar: {
  //   marginLeft: drawerWidth,
  //   [theme.breakpoints.up('sm')]: {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //   },
  // },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuTitle: {
    marginLeft: theme.spacing(2)
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#eaeaea"
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Layout = props => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
          file(relativePath: {eq: "clear-industries-logo.png"}) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  const {container, children} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openTab, setOpenTab] = React.useState("podiums");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("tab");
    if ( query ){
      setOpenTab(query);
    } else {
      setOpenTab("podiums")
    }
  });

  const drawer = (
    <List>
      <div className={classes.toolbar}/>
      <Typography variant="overline" component="p" className={classes.menuTitle}>
        Products
      </Typography>
      <Link to="/?tab=podiums" >
        <div className={`${openTab === "podiums" ? "active-tab" : ""}`}>
          <ListItem button>
            <ListItemIcon> <DockIcon/></ListItemIcon>
            <ListItemText primary="Podiums & Pulpits"/>
          </ListItem>
        </div>
      </Link>
      <Link to="/products-retail?tab=retail" >
        <div className={`${openTab === "retail" ? "active-tab" : ""}`}>
        <ListItem button>
          <ListItemIcon> <StoreIcon/></ListItemIcon>
          <ListItemText primary="Retail"/>
        </ListItem>
        </div>
      </Link>
      <Link to="/products-hospitality?tab=hos">
        <div className={`${openTab === "hos" ? "active-tab" : ""}`}>
        <ListItem button>
          <ListItemIcon> <HotelIcon/></ListItemIcon>
          <ListItemText primary="Hospitality"/>
        </ListItem>
        </div>
      </Link>
      <Link to="/products-ad?tab=ad" >
        <div className={`${openTab === "ad" ? "active-tab" : ""}`}>
        <ListItem button>
          <ListItemIcon> <AddIcon/></ListItemIcon>
          <ListItemText primary="Ad Specialties"/>
        </ListItem>
        </div>
      </Link>
      <Link to="/products-point-of-purchase?tab=pop">
        <div className={`${openTab === "pop" ? "active-tab" : ""}`}>
        <ListItem button>
          <ListItemIcon> <PaymentIcon/></ListItemIcon>
          <ListItemText primary="Point of Purchase"/>
        </ListItem>
        </div>
      </Link>
      <Link to="/products-restaurant?tab=rest" >
        <div className={`${openTab === "rest" ? "active-tab" : ""}`}>
        <ListItem button>
          <ListItemIcon> <RestaurantIcon/></ListItemIcon>
          <ListItemText primary="Restaurant"/>
        </ListItem>
        </div>
      </Link>
      <Divider/>
      <Link to="about/#services">
      <ListItem button>
        <ListItemText primary="Services"/>
      </ListItem>
      </Link>
      <Divider/>
      <Link to="about/#about">
      <ListItem button>
        <ListItemText primary="About"/>
      </ListItem>
      </Link>
      <Divider/>
      <Link to="about/#contact">
      <ListItem button>
        <ListItemText primary="Contact"/>
      </ListItem>
      </Link>
      <Divider/>
    </List>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <ThemeProvider theme={clearTheme}>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      </Helmet>
      <div className={classes.root}>
        <CssBaseline/>
        <AppBar position="fixed" color={"secondary"} className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
            <Link to="/">
              {/*<div style={{maxWidth: "200px"}}>*/}
              {/*  <Img fluid={data.file.childImageSharp.fluid} />*/}
              {/*</div>*/}
              Clear Industries
            </Link>
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav className={`${classes.drawer} drawer-theme`} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              className="drawer-theme"
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
              {/*<footer>*/}
              {/*  © {new Date().getFullYear()}, Built with*/}
              {/*  {` `}*/}
              {/*  <a href="https://www.gatsbyjs.org">Gatsby</a>*/}
              {/*</footer>*/}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>{children}</main>

      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
