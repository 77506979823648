import { createMuiTheme } from '@material-ui/core';

export const clearTheme = createMuiTheme({
  // shadows: ["none"],
  palette: {
    primary: { main: "#418aff" },
    secondary: { main: "#FFFFFF" },
    error: { main: "#DC3521"},
    // type: "dark"
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: '#404552',
        width: '90%',
        color: '#FFFFFF'
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#FFFFFF"
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: "0px 2px 4px -1px rgba(0,100,255,0.1), 0px 4px 5px 0px rgba(0,100,255,0.05), 0px 1px 10px 0px rgba(0,100,255,0.05)",
      }
    },
    MuiCard: {
      root: {
        borderRadius: 1,
        boxShadow: "none",
      }
    },
    MuiTypography: {
      root: {
        maxWidth: "600px"
      }
    },
    MuiCardContent: {
      root: {
        padding: 0
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: "transparent",
        background: "black",
        backgroundImage: "linear-gradient(45deg, #0F6BFF, #1AB5FF)",
        color: "white",
        borderRadius: 1,
        boxShadow: "0px 2px 4px -1px rgba(0,100,255,0.2), 0px 4px 5px 0px rgba(0,100,255,0.14), 0px 1px 10px 0px rgba(0,100,255,0.12)",
      },
      root: {
        borderRadius: 1,
      }
    },
    // TODO: fix this forcing max width on insights filter
    // MuiInputBase: {
    //   color: "white",
    // },
    // MuiFormControl: {
    //   root: {
    //     width: "100%"
    //   },
    // },
    // MuiInput: {
    //   root: {
    //     width: "100%"
    //   },
    //   formControl: {
    //     width: "100%"
    //   }
    // },
    // MuiButton: {
    //   root: {
    //     borderRadius: 0,
    //   },
    //   outlined: {
    //     borderWidth: 1,
    //     borderColor: '#FFFFFF',
    //     padding: '5px 30px',
    //     '&:hover': {
    //       backgroundColor: "#ffc836",
    //       color: '#000',
    //       borderColor: "#ffc836"
    //     }
    //   },
    // },
  },
});
