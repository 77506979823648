import React from 'react'

const logo = {};

logo.main = <svg version="1.1" id="Layer_1" x="0px" y="0px"
	 width="574.371px" height="81px" viewBox="0 0 574.371 81" enable-background="new 0 0 574.371 81" >
<rect x="-32.445" y="-260.096" fill="none" width="869.867" height="59.036"/>
<text transform="matrix(1 0 0 1 -32.4453 -204.6567)" font-family="'Orbitron-Regular'" font-size="72">Clear Industries</text>
<rect x="-32.445" y="-108.289" fill="none" width="869.867" height="59.036"/>
<text transform="matrix(1 0 0 1 -32.4453 -79.1289)" font-family="'Chathura-Regular'" font-size="72">Clear Industries</text>
<g>
	<defs>
		<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="56" height="81">

				<feColorMatrix  type="matrix" values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0" color-interpolation-filters="sRGB" result="source"/>
		</filter>
	</defs>
	<mask maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="81" id="SVGID_1_">
		<g filter="url(#Adobe_OpacityMaskFilter)">
			<g>
				<g opacity="0.4">
					<path d="M51.102,18.587v15.986h-7.355v-8.728H27.857v41.877h22.46v7.062H20.012V18.587H51.102 M56.102,13.587h-5h-31.09h-5v5
						v56.197v5h5h30.306h5v-5v-7.062v-5h-5h-17.46V30.846h5.889v3.728v5h5h7.355h5v-5V18.587V13.587L56.102,13.587z"/>
				</g>
				<g>
					<polygon fill="#FFFFFF" points="5.238,65.385 5.238,4.188 41.328,4.188 41.328,25.175 28.973,25.175 28.973,16.446
						18.084,16.446 18.084,53.324 40.544,53.324 40.544,65.385 					"/>
					<path d="M38.828,6.688v15.986h-7.355v-8.729H15.584v41.878h22.46v7.061H7.738V6.688H38.828 M43.828,1.688h-5H7.738h-5v5v56.196
						v5h5h30.306h5v-5v-7.061v-5h-5h-17.46V18.946h5.889v3.729v5h5h7.355h5v-5V6.688V1.688L43.828,1.688z"/>
				</g>
			</g>
		</g>
	</mask>
	<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="46.0479" y1="25.7295" x2="4.0236" y2="60.1216">
		<stop  offset="0" style={{stopColor:"#1EB0D7"}}/>
		<stop  offset="0.9944" style={{stopColor: "#0031D8"}}/>
	</linearGradient>
	<rect mask="url(#SVGID_1_)" fill="url(#SVGID_2_)" width="56" height="81"/>
	<g>
		<g>
			<g>
				<g>
					<path fill="#404552" d="M64.512,8.537v52.178h-0.541V8.537H64.512 M67.512,5.537h-6.541v58.178h6.541V5.537L67.512,5.537z"/>
				</g>
				<g>
					<path fill="#404552" d="M105.649,27.9V44.88H88.425h-3v3v9.639v3h3h17.396v0.196H84.54V27.9H105.649 M85.425,44.511h3h13.942h3
						v-3V31.27v-3h-3H88.425h-3v3v10.241V44.511 M108.649,24.9H81.54v38.814h27.281v-6.196H88.425V47.88h20.225V24.9L108.649,24.9z
						 M88.425,41.511V31.27h13.942v10.241H88.425L88.425,41.511z"/>
				</g>
				<g>
					<path fill="#404552" d="M147.218,27.9v32.814h-21.11V43.822h17.226h3v-3v-9.725v-3h-3h-17.397V27.9H147.218 M126.477,60.347h3
						h13.856h3v-3V47.104v-3h-3h-13.856h-3v3v10.242V60.347 M150.218,24.9h-27.282v6.197h20.397v9.725h-20.226v22.893h27.11V24.9
						L150.218,24.9z M129.477,57.347V47.104h13.856v10.242H129.477L129.477,57.347z"/>
				</g>
				<g>
					<path fill="#404552" d="M184.225,27.9v8.114h-0.541V31.27v-3.027l-3.027,0.027l-9.467,0.086l-2.973,0.027v2.973v29.359h-0.885
						V27.9H184.225 M187.225,24.9h-22.893v38.814h6.885V31.355l9.467-0.086v7.745h6.541V24.9L187.225,24.9z"/>
				</g>
				<g>
					<path fill="#404552" d="M230.096,17.401v0.283h-2.853h-3v3v36.662v3h3h3.282v0.368h-12.589v-0.368h2.766h3v-3V20.685v-3h-3
						h-3.282v-0.283H230.096 M233.096,14.401H214.42v6.283h6.282v36.662h-5.766v6.368h18.589v-6.368h-6.282V20.685h5.853V14.401
						L233.096,14.401z"/>
				</g>
				<g>
					<path fill="#404552" d="M269.254,27.9v32.814h-0.885V31.098v-3h-3h-13.855h-3v3v29.617h-0.541V27.9H269.254 M272.254,24.9
						h-27.281v38.814h6.541V31.098h13.855v32.617h6.885V24.9L272.254,24.9z"/>
				</g>
				<g>
					<path fill="#404552" d="M310.65,8.537v52.178h-21.282V27.9h17.827h3v-3V8.537H310.65 M310.195,60.365v-3.019V31.098v-3h-3
						h-13.941h-3v3v26.163v2.981l2.981,0.019l13.941,0.086L310.195,60.365 M313.65,5.537h-6.455V24.9h-20.827v38.814h27.282V5.537
						L313.65,5.537z M307.195,57.347l-13.941-0.086V31.098h13.941V57.347L307.195,57.347z"/>
				</g>
				<g>
					<path fill="#404552" d="M352.047,27.9v32.814h-21.282V27.9h0.541v29.618v3h3h13.855h3v-3V27.9H352.047 M355.047,24.9h-6.886
						v32.618h-13.855V24.9h-6.541v38.814h27.282V24.9L355.047,24.9z"/>
				</g>
				<g>
					<path fill="#404552" d="M393.442,27.9v8.114h-0.454v-4.659v-3h-3h-13.942h-3v3v7.659v2.379l2.316,0.542l17.908,4.192v14.511
						l-21.109,0.066V56.99h0.54v0.271v3h3h13.856h3v-3v-7.315v-2.384l-2.322-0.538l-18.074-4.195V27.9H393.442 M396.442,24.9
						h-27.281v20.312l20.396,4.733v7.315h-13.856V53.99h-6.54v9.725l27.109-0.086v-19.88l-20.225-4.734v-7.659h13.942v7.659h6.454
						V24.9L396.442,24.9z"/>
				</g>
				<g>
					<path fill="#404552" d="M422.016,16.455V24.9v3h3h6.295v0.197h-6.295h-3v3v26.421v3h3h6.295v0.196h-9.836V31.098v-3h-3h-4.918
						V27.9h4.918h3v-3v-8.445H422.016 M425.016,13.455h-6.541V24.9h-7.918v6.197h7.918v32.617h15.836v-6.196h-9.295V31.098h9.295
						V24.9h-9.295V13.455L425.016,13.455z"/>
				</g>
				<g>
					<path fill="#404552" d="M468.317,27.9v8.114h-0.541V31.27v-3.027l-3.027,0.027l-9.467,0.086l-2.973,0.027v2.973v29.359h-0.885
						V27.9H468.317 M471.317,24.9h-22.893v38.814h6.885V31.355l9.467-0.086v7.745h6.541V24.9L471.317,24.9z"/>
				</g>
				<g>
					<path fill="#404552" d="M487.165,8.537v5.446h-0.541V8.537H487.165 M487.165,27.94v32.774h-0.541V27.947L487.165,27.94
						 M490.165,5.537h-6.541v11.446h6.541V5.537L490.165,5.537z M490.165,24.9l-6.541,0.087v38.728h6.541V24.9L490.165,24.9z"/>
				</g>
				<g>
					<path fill="#404552" d="M528.303,27.9V44.88h-17.225h-3v3v9.639v3h3h17.396v0.196h-21.281V27.9H528.303 M508.078,44.511h3
						h13.942h3v-3V31.27v-3h-3h-13.942h-3v3v10.241V44.511 M531.303,24.9h-27.109v38.814h27.281v-6.196h-20.396V47.88h20.225V24.9
						L531.303,24.9z M511.078,41.511V31.27h13.942v10.241H511.078L511.078,41.511z"/>
				</g>
				<g>
					<path fill="#404552" d="M569.871,27.9v8.114h-0.455v-4.659v-3h-3h-13.941h-3v3v7.659v2.379l2.316,0.542l17.908,4.192v14.511
						l-21.11,0.066V56.99h0.541v0.271v3h3h13.856h3v-3v-7.315v-2.384l-2.322-0.538l-18.075-4.195V27.9H569.871 M572.871,24.9
						h-27.282v20.312l20.397,4.733v7.315H552.13V53.99h-6.541v9.725l27.11-0.086v-19.88l-20.225-4.734v-7.659h13.941v7.659h6.455
						V24.9L572.871,24.9z"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M9.603-375.4H3.267v-8.568c0-0.959-0.144-1.571-0.432-1.836
		c-0.288-0.263-0.889-0.396-1.8-0.396h-18.432c-0.912,0-1.512,0.133-1.8,0.396c-0.288,0.265-0.432,0.876-0.432,1.836v31.824
		c0,0.961,0.144,1.573,0.432,1.836c0.288,0.265,0.888,0.396,1.8,0.396H1.035c0.911,0,1.512-0.131,1.8-0.396
		c0.288-0.263,0.432-0.875,0.432-1.836v-8.568h6.336v9.36c0,3.792-1.896,5.688-5.688,5.688h-24.192
		c-3.792,0-5.688-1.896-5.688-5.688v-33.408c0-3.791,1.896-5.688,5.688-5.688H3.915c3.792,0,5.688,1.897,5.688,5.688V-375.4z"/>
	<path opacity="0.2" fill="none" stroke="#000000" stroke-miterlimit="10" d="M20.04-363.962h-6.336v-8.568
		c0-0.959-0.144-1.571-0.432-1.836c-0.288-0.263-0.889-0.396-1.8-0.396H-6.96c-0.912,0-1.512,0.133-1.8,0.396
		c-0.288,0.265-0.432,0.876-0.432,1.836v31.824c0,0.961,0.144,1.573,0.432,1.836c0.288,0.265,0.888,0.396,1.8,0.396h18.432
		c0.911,0,1.512-0.131,1.8-0.396c0.288-0.263,0.432-0.875,0.432-1.836v-8.568h6.336v9.36c0,3.792-1.896,5.688-5.688,5.688H-9.84
		c-3.792,0-5.688-1.896-5.688-5.688v-33.408c0-3.791,1.896-5.688,5.688-5.688h24.192c3.792,0,5.688,1.897,5.688,5.688V-363.962z"/>
	<path d="M27.314-345.664h-6.192v-48.168h6.192V-345.664z"/>
	<path d="M45.962-380.584H68.21c3.792,0,5.688,1.896,5.688,5.688v13.464H46.466v9.288c0,0.961,0.144,1.573,0.432,1.836
		c0.288,0.265,0.888,0.396,1.8,0.396H65.33c0.959,0,1.572-0.131,1.836-0.396c0.263-0.263,0.396-0.875,0.396-1.836v-3.384h6.12v4.176
		c0,3.792-1.896,5.688-5.688,5.688H45.962c-3.792,0-5.688-1.896-5.688-5.688v-23.544C40.274-378.688,42.17-380.584,45.962-380.584z
		 M46.466-365.68h21.312v-8.424c0-0.911-0.144-1.512-0.432-1.8c-0.288-0.288-0.889-0.432-1.8-0.432H48.698
		c-0.913,0-1.512,0.144-1.8,0.432c-0.288,0.288-0.432,0.889-0.432,1.8V-365.68z"/>
	<path d="M111.41-380.584h6.192v34.92h-6.192v-2.304c-0.625,1.537-1.944,2.304-3.96,2.304H90.386c-3.792,0-5.688-1.896-5.688-5.688
		v-23.544c0-3.792,1.896-5.688,5.688-5.688h17.064c2.016,0,3.335,0.768,3.96,2.304V-380.584z M93.122-349.912h15.84
		c0.864,0,1.487-0.191,1.872-0.576c0.384-0.383,0.576-1.031,0.576-1.944v-21.384c0-0.912-0.192-1.56-0.576-1.944
		c-0.385-0.383-1.008-0.576-1.872-0.576h-15.84c-0.913,0-1.512,0.144-1.8,0.432c-0.288,0.288-0.432,0.889-0.432,1.8v21.96
		c0,0.961,0.144,1.573,0.432,1.836C91.609-350.043,92.209-349.912,93.122-349.912z"/>
	<path d="M142.37-380.584h7.488v4.392h-8.424c-3.121,0-4.68,1.8-4.68,5.4v25.128h-6.192v-34.92h6.192v4.392
		C137.521-379.119,139.393-380.584,142.37-380.584z"/>
	<path d="M178.224-390.448h6.336v44.784h-6.336V-390.448z"/>
	<path d="M209.184-380.584h16.992c3.791,0,5.688,1.896,5.688,5.688v29.232h-6.191v-28.44c0-0.911-0.133-1.512-0.396-1.8
		c-0.264-0.288-0.876-0.432-1.836-0.432h-15.768c-0.863,0-1.488,0.192-1.872,0.576c-0.385,0.385-0.575,1.033-0.575,1.944v2.376
		h-0.072v25.776h-6.192v-34.92h6.192v2.52C205.68-379.744,207.023-380.584,209.184-380.584z"/>
	<path d="M270.096-393.832h6.192v48.168h-6.192v-2.304c-0.624,1.537-1.943,2.304-3.959,2.304h-17.064
		c-3.793,0-5.688-1.896-5.688-5.688v-23.544c0-3.792,1.896-5.688,5.688-5.688h17.064c2.016,0,3.335,0.768,3.959,2.304V-393.832z
		 M251.809-349.912h15.84c0.863,0,1.486-0.191,1.871-0.576c0.385-0.383,0.576-1.031,0.576-1.944v-21.384
		c0-0.912-0.191-1.56-0.576-1.944c-0.385-0.383-1.008-0.576-1.871-0.576h-15.84c-0.913,0-1.513,0.144-1.801,0.432
		c-0.287,0.288-0.432,0.889-0.432,1.8v21.96c0,0.961,0.145,1.573,0.432,1.836C250.296-350.043,250.896-349.912,251.809-349.912z"/>
	<path d="M315.24-380.584h6.191v34.92h-6.191v-2.304c-0.625,1.537-1.944,2.304-3.96,2.304h-17.063c-3.793,0-5.689-1.896-5.689-5.688
		v-29.232h6.193v28.44c0,0.961,0.144,1.573,0.432,1.836c0.287,0.265,0.887,0.396,1.8,0.396h15.84c0.864,0,1.487-0.191,1.872-0.576
		c0.384-0.383,0.576-1.031,0.576-1.944V-380.584z"/>
	<path d="M358.225-352.072v-6.624c0-0.911-0.133-1.512-0.396-1.8s-0.877-0.432-1.836-0.432H339.36c-3.792,0-5.688-1.896-5.688-5.688
		v-8.28c0-3.792,1.896-5.688,5.688-5.688h19.151c3.791,0,5.688,1.896,5.688,5.688v3.744h-6.048v-3.024
		c0-0.959-0.145-1.571-0.432-1.836c-0.289-0.263-0.889-0.396-1.801-0.396h-13.896c-0.96,0-1.572,0.133-1.836,0.396
		c-0.264,0.265-0.396,0.876-0.396,1.836v6.768c0,0.961,0.132,1.573,0.396,1.836c0.264,0.265,0.876,0.396,1.836,0.396h16.633
		c3.791,0,5.688,1.897,5.688,5.688v8.136c0,3.792-1.896,5.688-5.688,5.688h-19.152c-3.792,0-5.688-1.896-5.688-5.688v-3.744h6.119
		v3.024c0,0.913,0.133,1.512,0.396,1.8s0.875,0.432,1.836,0.432h13.824c0.959,0,1.572-0.144,1.836-0.432
		S358.225-351.16,358.225-352.072z"/>
	<path d="M392.855-376.336h-8.784v23.832c0,0.912,0.144,1.512,0.433,1.8c0.287,0.288,0.887,0.432,1.799,0.432h6.48v4.608h-9.216
		c-3.792,0-5.688-1.896-5.688-5.688v-24.984h-5.615v-4.248h5.615v-6.048h6.192v6.048h8.784V-376.336z"/>
	<path d="M414.023-380.584h7.488v4.392h-8.424c-3.121,0-4.681,1.8-4.681,5.4v25.128h-6.192v-34.92h6.192v4.392
		C409.175-379.119,411.047-380.584,414.023-380.584z"/>
	<path d="M429.287-387.712v-6.192h6.553v6.192H429.287z M435.623-345.664h-6.191v-34.92h6.191V-345.664z"/>
	<path d="M454.271-380.584h22.248c3.791,0,5.688,1.896,5.688,5.688v13.464h-27.432v9.288c0,0.961,0.144,1.573,0.432,1.836
		c0.288,0.265,0.888,0.396,1.8,0.396h16.633c0.959,0,1.571-0.131,1.836-0.396c0.263-0.263,0.396-0.875,0.396-1.836v-3.384h6.12
		v4.176c0,3.792-1.896,5.688-5.688,5.688h-22.032c-3.793,0-5.688-1.896-5.688-5.688v-23.544
		C448.582-378.688,450.478-380.584,454.271-380.584z M454.774-365.68h21.312v-8.424c0-0.911-0.144-1.512-0.432-1.8
		c-0.288-0.288-0.889-0.432-1.8-0.432h-16.849c-0.912,0-1.512,0.144-1.8,0.432c-0.288,0.288-0.432,0.889-0.432,1.8V-365.68z"/>
	<path d="M517.557-352.072v-6.624c0-0.911-0.132-1.512-0.396-1.8c-0.265-0.288-0.876-0.432-1.836-0.432h-16.632
		c-3.793,0-5.688-1.896-5.688-5.688v-8.28c0-3.792,1.896-5.688,5.688-5.688h19.151c3.792,0,5.688,1.896,5.688,5.688v3.744h-6.048
		v-3.024c0-0.959-0.145-1.571-0.433-1.836c-0.288-0.263-0.889-0.396-1.8-0.396h-13.896c-0.961,0-1.573,0.133-1.836,0.396
		c-0.265,0.265-0.396,0.876-0.396,1.836v6.768c0,0.961,0.132,1.573,0.396,1.836c0.263,0.265,0.875,0.396,1.836,0.396h16.632
		c3.791,0,5.688,1.897,5.688,5.688v8.136c0,3.792-1.896,5.688-5.688,5.688h-19.152c-3.792,0-5.688-1.896-5.688-5.688v-3.744h6.12
		v3.024c0,0.913,0.131,1.512,0.396,1.8c0.264,0.288,0.875,0.432,1.836,0.432h13.824c0.96,0,1.571-0.144,1.836-0.432
		C517.425-350.56,517.557-351.16,517.557-352.072z"/>
</g>
<g>
	<g opacity="0.4">
		<path d="M121.523,128.646v15.986h-7.355v-8.728H98.279v41.877h22.459v7.062H90.434v-56.197H121.523 M126.523,123.646h-5h-31.09h-5
			v5v56.197v5h5h30.305h5v-5v-7.062v-5h-5h-17.459v-31.877h5.889v3.728v5h5h7.355h5v-5v-15.986V123.646L126.523,123.646z"/>
	</g>
	<g>
		<polygon fill="#FFFFFF" points="76.91,176.569 76.91,115.373 113,115.373 113,136.359 100.645,136.359 100.645,127.631
			89.756,127.631 89.756,164.509 112.215,164.509 112.215,176.569 		"/>
		<path d="M110.5,117.873v15.986h-7.355v-8.729H87.256v41.878h22.459v7.061H79.41v-56.196H110.5 M115.5,112.873h-5H79.41h-5v5
			v56.196v5h5h30.305h5v-5v-7.061v-5h-5H92.256v-31.878h5.889v3.729v5h5h7.355h5v-5v-15.986V112.873L115.5,112.873z"/>
	</g>
</g>
<g opacity="0.4">
	<path d="M-132.887,18.107v7.438h-7.355v-0.18h-15.889v41.877h22.459v7.062h-30.305V18.107H-132.887 M-127.887,13.107h-5h-31.09h-5
		v5v56.197v5h5h30.305h5v-5v-7.062v-5h-5h-17.459V30.366h5.889v0.18h5h7.355h5v-5v-7.438V13.107L-127.887,13.107z"/>
</g>
<g>
	<polygon fill="#FFFFFF" points="-177.5,66.03 -177.5,4.833 -141.41,4.833 -141.41,17.333 -153.766,17.333 -153.766,17.092
		-164.654,17.092 -164.654,53.97 -142.195,53.97 -142.195,66.03 	"/>
	<path d="M-143.91,7.333v7.5h-7.355v-0.242h-15.889V56.47h22.459v7.061H-175V7.333H-143.91 M-138.91,2.333h-5H-175h-5v5V63.53v5h5
		h30.305h5v-5V56.47v-5h-5h-17.459V19.592h5.889v0.242h5h7.355h5v-5v-7.5V2.333L-138.91,2.333z"/>
</g>
<g>
	<g opacity="0.4">
		<path d="M16.089,174.405v7.5H-3.545v-0.242h-15.889v41.878h35.523v7.061H-27.28v-56.196H16.089 M21.089,169.405h-5H-27.28h-5v5
			v56.196v5h5h43.369h5v-5v-7.061v-5h-5h-30.523v-31.878h5.889v0.242h5h19.635h5v-5v-7.5V169.405L21.089,169.405z"/>
	</g>
	<g>
		<polygon fill="#FFFFFF" points="-42.53,220.58 -42.53,159.383 5.839,159.383 5.839,171.883 -18.795,171.883 -18.795,171.642
			-29.684,171.642 -29.684,208.52 5.839,208.52 5.839,220.58 		"/>
		<path d="M3.339,161.883v7.5h-19.635v-0.241h-15.889v41.878H3.339v7.061H-40.03v-56.197H3.339 M8.339,156.883h-5H-40.03h-5v5
			v56.197v5h5H3.339h5v-5v-7.061v-5h-5h-30.523v-31.878h5.889v0.242h5H3.339h5v-5.001v-7.5V156.883L8.339,156.883z"/>
	</g>
</g>
</svg>;

	logo.main2 = <svg version="1.1" id="Layer_1" x="0px" y="0px"
				 width="618.711px" height="84.103px" viewBox="0 0 618.711 84.103" enable-background="new 0 0 618.711 84.103"
				 >
<g>
	<rect x="56" y="13.534" fill="none" width="560.196" height="59.035"/>

	<text transform="matrix(1 0 0 1 56 66.1621)" fill="#404552" font-family="'Righteous-Regular'" font-size="72" letter-spacing="5">lear Industries</text>
	<g>
		<defs>
			<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="56" height="81">

					<feColorMatrix  type="matrix" values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0" color-interpolation-filters="sRGB" result="source"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="81" id="SVGID_1_">
			<g filter="url(#Adobe_OpacityMaskFilter)">
				<g>
					<g opacity="0.4">
						<path d="M51.102,18.587v15.986h-7.355v-8.728H27.857v41.877h22.46v7.062H20.012V18.587H51.102 M56.102,13.587h-5h-31.09h-5v5
							v56.197v5h5h30.306h5v-5v-7.062v-5h-5h-17.46V30.846h5.889v3.728v5h5h7.355h5v-5V18.587V13.587L56.102,13.587z"/>
					</g>
					<g>
						<polygon fill="#FFFFFF" points="5.238,65.385 5.238,4.188 41.328,4.188 41.328,25.175 28.973,25.175 28.973,16.446
							18.084,16.446 18.084,53.324 40.544,53.324 40.544,65.385 						"/>
						<path d="M38.828,6.688v15.986h-7.355v-8.729H15.584v41.878h22.46v7.061H7.738V6.688H38.828 M43.828,1.688h-5H7.738h-5v5
							v56.196v5h5h30.306h5v-5v-7.061v-5h-5h-17.46V18.946h5.889v3.729v5h5h7.355h5v-5V6.688V1.688L43.828,1.688z"/>
					</g>
				</g>
			</g>
		</mask>
		<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="46.0469" y1="25.7285" x2="4.0228" y2="60.1205">
			<stop  offset="0" style={{stopColor:"#1EB0D7"}}/>
			<stop  offset="0.9944" style={{stopColor:"#0031D8"}}/>
		</linearGradient>
		<rect mask="url(#SVGID_1_)" fill="url(#SVGID_2_)" width="56" height="81"/>
	</g>
</g>
			<rect x="-2052.971" y="-98.927" fill="none" width="869.867" height="59.036"/>
			<text transform="matrix(1 0 0 1 -2052.9712 -69.7666)" font-family="'Chathura-Regular'" font-size="72">Clear Industries</text>
			<g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M-2010.923-366.038h-6.336v-8.568c0-0.959-0.144-1.571-0.432-1.836
		c-0.288-0.263-0.889-0.396-1.8-0.396h-18.432c-0.912,0-1.512,0.133-1.8,0.396c-0.288,0.265-0.432,0.876-0.432,1.836v31.824
		c0,0.961,0.144,1.573,0.432,1.836c0.288,0.265,0.888,0.396,1.8,0.396h18.432c0.911,0,1.512-0.131,1.8-0.396
		c0.288-0.263,0.432-0.875,0.432-1.836v-8.568h6.336v9.36c0,3.792-1.896,5.688-5.688,5.688h-24.192
		c-3.792,0-5.688-1.896-5.688-5.688v-33.408c0-3.791,1.896-5.688,5.688-5.688h24.192c3.792,0,5.688,1.897,5.688,5.688V-366.038z"/>
				<path opacity="0.2" fill="none" stroke="#000000" stroke-miterlimit="10" d="M-2000.486-354.6h-6.336v-8.568
		c0-0.959-0.144-1.571-0.432-1.836c-0.288-0.263-0.889-0.396-1.8-0.396h-18.432c-0.912,0-1.512,0.133-1.8,0.396
		c-0.288,0.265-0.432,0.876-0.432,1.836v31.824c0,0.961,0.144,1.573,0.432,1.836c0.288,0.265,0.888,0.396,1.8,0.396h18.432
		c0.911,0,1.512-0.131,1.8-0.396c0.288-0.263,0.432-0.875,0.432-1.836v-8.568h6.336v9.36c0,3.792-1.896,5.688-5.688,5.688h-24.192
		c-3.792,0-5.688-1.896-5.688-5.688v-33.408c0-3.791,1.896-5.688,5.688-5.688h24.192c3.792,0,5.688,1.897,5.688,5.688V-354.6z"/>
				<path d="M-1993.211-336.302h-6.192v-48.168h6.192V-336.302z"/>
				<path d="M-1974.563-371.222h22.248c3.792,0,5.688,1.896,5.688,5.688v13.464h-27.432v9.288c0,0.961,0.144,1.573,0.432,1.836
		c0.288,0.265,0.888,0.396,1.8,0.396h16.632c0.959,0,1.572-0.131,1.836-0.396c0.263-0.263,0.396-0.875,0.396-1.836v-3.384h6.12
		v4.176c0,3.792-1.896,5.688-5.688,5.688h-22.032c-3.792,0-5.688-1.896-5.688-5.688v-23.544
		C-1980.252-369.325-1978.356-371.222-1974.563-371.222z M-1974.06-356.318h21.312v-8.424c0-0.911-0.144-1.512-0.432-1.8
		c-0.288-0.288-0.889-0.432-1.8-0.432h-16.848c-0.913,0-1.512,0.144-1.8,0.432c-0.288,0.288-0.432,0.889-0.432,1.8V-356.318z"/>
				<path d="M-1909.116-371.222h6.192v34.92h-6.192v-2.304c-0.625,1.537-1.944,2.304-3.96,2.304h-17.064
		c-3.792,0-5.688-1.896-5.688-5.688v-23.544c0-3.792,1.896-5.688,5.688-5.688h17.064c2.016,0,3.335,0.768,3.96,2.304V-371.222z
		 M-1927.404-340.55h15.84c0.864,0,1.487-0.191,1.872-0.576c0.384-0.383,0.576-1.031,0.576-1.944v-21.384
		c0-0.912-0.192-1.56-0.576-1.944c-0.385-0.383-1.008-0.576-1.872-0.576h-15.84c-0.913,0-1.512,0.144-1.8,0.432
		c-0.288,0.288-0.432,0.889-0.432,1.8v21.96c0,0.961,0.144,1.573,0.432,1.836C-1928.917-340.681-1928.317-340.55-1927.404-340.55z"
				/>
				<path d="M-1878.156-371.222h7.488v4.392h-8.424c-3.121,0-4.68,1.8-4.68,5.4v25.128h-6.192v-34.92h6.192v4.392
		C-1883.005-369.757-1881.133-371.222-1878.156-371.222z"/>
				<path d="M-1842.302-381.086h6.336v44.784h-6.336V-381.086z"/>
				<path d="M-1811.342-371.222h16.992c3.791,0,5.688,1.896,5.688,5.688v29.232h-6.191v-28.44c0-0.911-0.133-1.512-0.396-1.8
		c-0.264-0.288-0.876-0.432-1.836-0.432h-15.768c-0.863,0-1.488,0.192-1.872,0.576c-0.385,0.385-0.575,1.033-0.575,1.944v2.376
		h-0.072v25.776h-6.192v-34.92h6.192v2.52C-1814.846-370.381-1813.502-371.222-1811.342-371.222z"/>
				<path d="M-1750.43-384.47h6.192v48.168h-6.192v-2.304c-0.624,1.537-1.943,2.304-3.959,2.304h-17.064
		c-3.793,0-5.688-1.896-5.688-5.688v-23.544c0-3.792,1.896-5.688,5.688-5.688h17.064c2.016,0,3.335,0.768,3.959,2.304V-384.47z
		 M-1768.717-340.55h15.84c0.863,0,1.486-0.191,1.871-0.576c0.385-0.383,0.576-1.031,0.576-1.944v-21.384
		c0-0.912-0.191-1.56-0.576-1.944c-0.385-0.383-1.008-0.576-1.871-0.576h-15.84c-0.913,0-1.513,0.144-1.801,0.432
		c-0.287,0.288-0.432,0.889-0.432,1.8v21.96c0,0.961,0.145,1.573,0.432,1.836C-1770.23-340.681-1769.63-340.55-1768.717-340.55z"/>
				<path d="M-1705.286-371.222h6.191v34.92h-6.191v-2.304c-0.625,1.537-1.944,2.304-3.96,2.304h-17.063
		c-3.793,0-5.689-1.896-5.689-5.688v-29.232h6.193v28.44c0,0.961,0.144,1.573,0.432,1.836c0.287,0.265,0.887,0.396,1.8,0.396h15.84
		c0.864,0,1.487-0.191,1.872-0.576c0.384-0.383,0.576-1.031,0.576-1.944V-371.222z"/>
				<path d="M-1662.301-342.71v-6.624c0-0.911-0.133-1.512-0.396-1.8s-0.877-0.432-1.836-0.432h-16.632
		c-3.792,0-5.688-1.896-5.688-5.688v-8.28c0-3.792,1.896-5.688,5.688-5.688h19.151c3.791,0,5.688,1.896,5.688,5.688v3.744h-6.048
		v-3.024c0-0.959-0.145-1.571-0.432-1.836c-0.289-0.263-0.889-0.396-1.801-0.396h-13.896c-0.96,0-1.572,0.133-1.836,0.396
		c-0.264,0.265-0.396,0.876-0.396,1.836v6.768c0,0.961,0.132,1.573,0.396,1.836c0.264,0.265,0.876,0.396,1.836,0.396h16.633
		c3.791,0,5.688,1.897,5.688,5.688v8.136c0,3.792-1.896,5.688-5.688,5.688h-19.152c-3.792,0-5.688-1.896-5.688-5.688v-3.744h6.119
		v3.024c0,0.913,0.133,1.512,0.396,1.8s0.875,0.432,1.836,0.432h13.824c0.959,0,1.572-0.144,1.836-0.432
		S-1662.301-341.797-1662.301-342.71z"/>
				<path d="M-1627.67-366.974h-8.784v23.832c0,0.912,0.144,1.512,0.433,1.8c0.287,0.288,0.887,0.432,1.799,0.432h6.48v4.608h-9.216
		c-3.792,0-5.688-1.896-5.688-5.688v-24.984h-5.615v-4.248h5.615v-6.048h6.192v6.048h8.784V-366.974z"/>
				<path d="M-1606.502-371.222h7.488v4.392h-8.424c-3.121,0-4.681,1.8-4.681,5.4v25.128h-6.192v-34.92h6.192v4.392
		C-1611.351-369.757-1609.479-371.222-1606.502-371.222z"/>
				<path d="M-1591.239-378.35v-6.192h6.553v6.192H-1591.239z M-1584.903-336.302h-6.191v-34.92h6.191V-336.302z"/>
				<path d="M-1566.255-371.222h22.248c3.791,0,5.688,1.896,5.688,5.688v13.464h-27.432v9.288c0,0.961,0.144,1.573,0.432,1.836
		c0.288,0.265,0.888,0.396,1.8,0.396h16.633c0.959,0,1.571-0.131,1.836-0.396c0.263-0.263,0.396-0.875,0.396-1.836v-3.384h6.12
		v4.176c0,3.792-1.896,5.688-5.688,5.688h-22.032c-3.793,0-5.688-1.896-5.688-5.688v-23.544
		C-1571.944-369.325-1570.048-371.222-1566.255-371.222z M-1565.751-356.318h21.312v-8.424c0-0.911-0.144-1.512-0.432-1.8
		c-0.288-0.288-0.889-0.432-1.8-0.432h-16.849c-0.912,0-1.512,0.144-1.8,0.432c-0.288,0.288-0.432,0.889-0.432,1.8V-356.318z"/>
				<path d="M-1502.969-342.71v-6.624c0-0.911-0.132-1.512-0.396-1.8c-0.265-0.288-0.876-0.432-1.836-0.432h-16.632
		c-3.793,0-5.688-1.896-5.688-5.688v-8.28c0-3.792,1.896-5.688,5.688-5.688h19.151c3.792,0,5.688,1.896,5.688,5.688v3.744h-6.048
		v-3.024c0-0.959-0.145-1.571-0.433-1.836c-0.288-0.263-0.889-0.396-1.8-0.396h-13.896c-0.961,0-1.573,0.133-1.836,0.396
		c-0.265,0.265-0.396,0.876-0.396,1.836v6.768c0,0.961,0.132,1.573,0.396,1.836c0.263,0.265,0.875,0.396,1.836,0.396h16.632
		c3.791,0,5.688,1.897,5.688,5.688v8.136c0,3.792-1.896,5.688-5.688,5.688h-19.152c-3.792,0-5.688-1.896-5.688-5.688v-3.744h6.12
		v3.024c0,0.913,0.131,1.512,0.396,1.8c0.264,0.288,0.875,0.432,1.836,0.432h13.824c0.96,0,1.571-0.144,1.836-0.432
		C-1503.101-341.198-1502.969-341.797-1502.969-342.71z"/>
</g>
			<g>
	<g opacity="0.4">
		<path d="M-1899.002,138.009v15.986h-7.355v-8.728h-15.889v41.877h22.459v7.062h-30.305v-56.197H-1899.002 M-1894.002,133.009h-5
			h-31.09h-5v5v56.197v5h5h30.305h5v-5v-7.062v-5h-5h-17.459v-31.877h5.889v3.728v5h5h7.355h5v-5v-15.986V133.009L-1894.002,133.009
			z"/>
	</g>
				<g>
		<polygon fill="#FFFFFF" points="-1943.616,185.932 -1943.616,124.735 -1907.526,124.735 -1907.526,145.722 -1919.881,145.722
			-1919.881,136.993 -1930.77,136.993 -1930.77,173.871 -1908.311,173.871 -1908.311,185.932 		"/>
					<path d="M-1910.026,127.235v15.986h-7.355v-8.729h-15.889v41.878h22.459v7.061h-30.305v-56.196H-1910.026 M-1905.026,122.235h-5
			h-31.09h-5v5v56.196v5h5h30.305h5v-5v-7.061v-5h-5h-17.459v-31.878h5.889v3.729v5h5h7.355h5v-5v-15.986V122.235L-1905.026,122.235
			z"/>
	</g>
</g>
			<g opacity="0.4">
	<path d="M-2153.413,27.47v7.438h-7.355v-0.18h-15.889v41.877h22.459v7.062h-30.305V27.47H-2153.413 M-2148.413,22.47h-5h-31.09h-5
		v5v56.197v5h5h30.305h5v-5v-7.062v-5h-5h-17.459V39.729h5.889v0.18h5h7.355h5v-5V27.47V22.47L-2148.413,22.47z"/>
</g>
			<g>
	<polygon fill="#FFFFFF" points="-2198.026,75.393 -2198.026,14.196 -2161.936,14.196 -2161.936,26.695 -2174.292,26.695
		-2174.292,26.454 -2185.18,26.454 -2185.18,63.332 -2162.721,63.332 -2162.721,75.393 	"/>
				<path d="M-2164.436,16.696v7.5h-7.355v-0.242h-15.889v41.878h22.459v7.061h-30.305V16.696H-2164.436 M-2159.436,11.696h-5h-31.09
		h-5v5v56.197v5h5h30.305h5v-5v-7.061v-5h-5h-17.459V28.954h5.889v0.242h5h7.355h5v-5v-7.5V11.696L-2159.436,11.696z"/>
</g>
			<g>
	<g opacity="0.4">
		<path d="M-2004.437,183.768v7.5h-19.635v-0.242h-15.889v41.878h35.523v7.061h-43.369v-56.196H-2004.437 M-1999.437,178.768h-5
			h-43.369h-5v5v56.196v5h5h43.369h5v-5v-7.061v-5h-5h-30.523v-31.878h5.889v0.242h5h19.635h5v-5v-7.5V178.768L-1999.437,178.768z"
		/>
	</g>
				<g>
		<polygon fill="#FFFFFF" points="-2063.056,229.942 -2063.056,168.745 -2014.687,168.745 -2014.687,181.245 -2039.321,181.245
			-2039.321,181.004 -2050.21,181.004 -2050.21,217.882 -2014.687,217.882 -2014.687,229.942 		"/>
					<path d="M-2017.187,171.245v7.5h-19.635v-0.241h-15.889v41.878h35.523v7.061h-43.369v-56.197H-2017.187 M-2012.187,166.245h-5
			h-43.369h-5v5v56.197v5h5h43.369h5v-5v-7.061v-5h-5h-30.523v-31.878h5.889v0.242h5h19.635h5v-5.001v-7.5V166.245
			L-2012.187,166.245z"/>
	</g>
</g>
			<defs>
	<filter id="Adobe_OpacityMaskFilter_1_" filterUnits="userSpaceOnUse" x="-1891" y="383" width="22.171" height="32.082">

			<feColorMatrix  type="matrix" values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0" color-interpolation-filters="sRGB" result="source"/>
	</filter>
</defs>
			<mask maskUnits="userSpaceOnUse" x="-1891" y="383" width="22.171" height="32.082" id="SVGID_3_">
	<g filter="url(#Adobe_OpacityMaskFilter_1_)">
		<g>
			<g opacity="0.4">
				<path d="M-1870.59,390.548v6.3h-2.899v-3.439h-6.26v16.499h8.85v2.782h-11.939v-22.142H-1870.59 M-1868.62,388.579h-1.97
					h-12.249h-1.972v1.969v22.142v1.971h1.972h11.939h1.971v-1.971v-2.782v-1.969h-1.971h-6.878v-12.56h2.319v1.469v1.97h1.969
					h2.899h1.97v-1.97v-6.3V388.579L-1868.62,388.579z"/>
			</g>
			<g>
				<polygon fill="#FFFFFF" points="-1888.661,408.986 -1888.661,384.875 -1874.441,384.875 -1874.441,393.145 -1879.309,393.145
					-1879.309,389.705 -1883.6,389.705 -1883.6,404.235 -1874.75,404.235 -1874.75,408.986 				"/>
				<path d="M-1875.426,385.861v6.299h-2.898v-3.439h-6.26v16.499h8.849v2.782h-11.938v-22.141H-1875.426 M-1873.457,383.891h-1.969
					h-12.248h-1.972v1.971v22.141v1.971h1.972h11.938h1.971v-1.971v-2.782v-1.97h-1.971h-6.879v-12.561h2.321v1.471v1.969h1.969
					h2.898h1.969v-1.969v-6.299V383.891L-1873.457,383.891z"/>
			</g>
		</g>
	</g>
</mask>
			<g mask="url(#SVGID_3_)">
	<g>
		<defs>
			<rect id="SVGID_4_" x="-1891" y="383" width="22" height="32"/>
		</defs>
		<clipPath id="SVGID_5_">
		</clipPath>
		<polygon clip-path="url(#SVGID_5_)" fill="#1EB0D7" points="-1869,398.154 -1880.877,383 -1869,383 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1EB0D7" points="-1869,398.42 -1881.094,383 -1880.877,383 -1869,398.154 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1EAFD7" points="-1869,398.682 -1881.311,383 -1881.094,383 -1869,398.42 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1EAED7" points="-1869,398.947 -1881.527,383 -1881.311,383 -1869,398.682 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1DADD7" points="-1869,399.212 -1881.744,383 -1881.527,383 -1869,398.947 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1DACD7" points="-1869,399.476 -1881.959,383 -1881.744,383 -1869,399.212 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1DABD7" points="-1869,399.741 -1882.177,383 -1881.959,383 -1869,399.476 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1DAAD7" points="-1869,400.006 -1882.392,383 -1882.177,383 -1869,399.741 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1CA9D7" points="-1869,400.271 -1882.608,383 -1882.392,383 -1869,400.006 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1CA8D7" points="-1869,400.533 -1882.826,383 -1882.608,383 -1869,400.271 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1CA7D7" points="-1869,400.798 -1883.041,383 -1882.826,383 -1869,400.533 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1CA6D7" points="-1869,401.063 -1883.259,383 -1883.041,383 -1869,400.798 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1BA5D7" points="-1869,401.328 -1883.475,383 -1883.259,383 -1869,401.063 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1BA4D7" points="-1869,401.593 -1883.692,383 -1883.475,383 -1869,401.328 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1BA3D7" points="-1869,401.857 -1883.907,383 -1883.692,383 -1869,401.593 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1BA2D7" points="-1869,402.121 -1884.124,383 -1883.907,383 -1869,401.857 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1AA1D7" points="-1869,402.387 -1884.34,383 -1884.124,383 -1869,402.121 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1AA0D7" points="-1869,402.651 -1884.558,383 -1884.34,383 -1869,402.387 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1A9FD7" points="-1869,402.915 -1884.774,383 -1884.558,383 -1869,402.651 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1A9ED7" points="-1869,403.179 -1884.989,383 -1884.774,383 -1869,402.915 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#199DD7" points="-1869,403.443 -1885.207,383 -1884.989,383 -1869,403.179 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#199CD7" points="-1869,403.709 -1885.423,383 -1885.207,383 -1869,403.443 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#199BD7" points="-1869,403.974 -1885.639,383 -1885.423,383 -1869,403.709 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#199AD7" points="-1869,404.235 -1885.855,383 -1885.639,383 -1869,403.974 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1999D7" points="-1869,404.501 -1886.073,383 -1885.855,383 -1869,404.235 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1898D7" points="-1869,404.767 -1886.288,383 -1886.073,383 -1869,404.501 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1897D7" points="-1869,405.031 -1886.506,383 -1886.288,383 -1869,404.767 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1896D7" points="-1869,405.297 -1886.723,383 -1886.506,383 -1869,405.031 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1895D7" points="-1869,405.561 -1886.938,383 -1886.723,383 -1869,405.297 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1794D7" points="-1869,405.824 -1887.155,383 -1886.938,383 -1869,405.561 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1793D7" points="-1869,406.089 -1887.373,383 -1887.155,383 -1869,405.824 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1792D7" points="-1869,406.354 -1887.588,383 -1887.373,383 -1869,406.089 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1791D7" points="-1869,406.618 -1887.806,383 -1887.588,383 -1869,406.354 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1690D7" points="-1869,406.882 -1888.021,383 -1887.806,383 -1869,406.618 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#168FD7" points="-1869,407.147 -1888.236,383 -1888.021,383 -1869,406.882 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#168ED7" points="-1869,407.411 -1888.454,383 -1888.236,383 -1869,407.147 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#168DD7" points="-1869,407.677 -1888.671,383 -1888.454,383 -1869,407.411 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#158CD7" points="-1869,407.941 -1888.887,383 -1888.671,383 -1869,407.677 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#158BD7" points="-1869,408.204 -1889.104,383 -1888.887,383 -1869,407.941 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#158AD7" points="-1869,408.469 -1889.321,383 -1889.104,383 -1869,408.204 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1589D7" points="-1869,408.733 -1889.536,383 -1889.321,383 -1869,408.469 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1488D7" points="-1869,408.999 -1889.752,383 -1889.536,383 -1869,408.733 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1487D7" points="-1869,409.264 -1889.97,383 -1889.752,383 -1869,408.999 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1486D7" points="-1869,409.527 -1890.186,383 -1889.97,383 -1869,409.264 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1485D7" points="-1869,409.792 -1890.402,383 -1890.186,383 -1869,409.527 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1484D7" points="-1869,410.058 -1890.618,383 -1890.402,383 -1869,409.792 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1383D7" points="-1869,410.321 -1891,383.36 -1891,383 -1890.618,383 -1869,410.058
			"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1382D7" points="-1869,410.585 -1891,383.625 -1891,383.36 -1869,410.321 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1381D7" points="-1869,410.851 -1891,383.891 -1891,383.625 -1869,410.585 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1380D7" points="-1869,411.114 -1891,384.156 -1891,383.891 -1869,410.851 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#127FD7" points="-1869,411.379 -1891,384.418 -1891,384.156 -1869,411.114 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#127ED7" points="-1869,411.645 -1891,384.683 -1891,384.418 -1869,411.379 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#127DD7" points="-1869,411.908 -1891,384.948 -1891,384.683 -1869,411.645 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#127CD7" points="-1869,412.172 -1891,385.213 -1891,384.948 -1869,411.908 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#117BD7" points="-1869,412.437 -1891,385.478 -1891,385.213 -1869,412.172 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#117AD7" points="-1869,412.702 -1891,385.741 -1891,385.478 -1869,412.437 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1179D7" points="-1869,412.967 -1891,386.007 -1891,385.741 -1869,412.702 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1178D7" points="-1869,413.23 -1891,386.271 -1891,386.007 -1869,412.967 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1077D7" points="-1869,413.496 -1891,386.535 -1891,386.271 -1869,413.23 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1076D7" points="-1869,413.761 -1891,386.799 -1891,386.535 -1869,413.496 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1075D7" points="-1869,414.025 -1891,387.063 -1891,386.799 -1869,413.761 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#1074D7" points="-1869,414.288 -1891,387.328 -1891,387.063 -1869,414.025 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0F73D7" points="-1869,414.554 -1891,387.594 -1891,387.328 -1869,414.288 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0F72D7" points="-1869,414.817 -1891,387.858 -1891,387.594 -1869,414.554 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0F71D8" points="-1869,415.082 -1891,388.123 -1891,387.858 -1869,414.817 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0F6FD8" points="-1868.829,415 -1891,388.389 -1891,388.123 -1869,415.082 -1869,415
					"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0F6ED8" points="-1869.047,415 -1891,388.65 -1891,388.389 -1868.829,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0E6DD8" points="-1869.264,415 -1891,388.916 -1891,388.65 -1869.047,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0E6CD8" points="-1869.479,415 -1891,389.181 -1891,388.916 -1869.264,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0E6BD8" points="-1869.696,415 -1891,389.446 -1891,389.181 -1869.479,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0E6AD8" points="-1869.912,415 -1891,389.71 -1891,389.446 -1869.696,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0D69D8" points="-1870.129,415 -1891,389.974 -1891,389.71 -1869.912,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0D68D8" points="-1870.346,415 -1891,390.238 -1891,389.974 -1870.129,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0D67D8" points="-1870.562,415 -1891,390.502 -1891,390.238 -1870.346,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0D66D8" points="-1870.777,415 -1891,390.767 -1891,390.502 -1870.562,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0C65D8" points="-1870.995,415 -1891,391.031 -1891,390.767 -1870.777,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0C64D8" points="-1871.212,415 -1891,391.297 -1891,391.031 -1870.995,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0C63D8" points="-1871.427,415 -1891,391.562 -1891,391.297 -1871.212,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0C62D8" points="-1871.645,415 -1891,391.826 -1891,391.562 -1871.427,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0B61D8" points="-1871.861,415 -1891,392.09 -1891,391.826 -1871.645,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0B60D8" points="-1872.077,415 -1891,392.355 -1891,392.09 -1871.861,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0B5FD8" points="-1872.295,415 -1891,392.618 -1891,392.355 -1872.077,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0B5ED8" points="-1872.51,415 -1891,392.882 -1891,392.618 -1872.295,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0A5DD8" points="-1872.726,415 -1891,393.147 -1891,392.882 -1872.51,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0A5CD8" points="-1872.943,415 -1891,393.413 -1891,393.147 -1872.726,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0A5BD8" points="-1873.16,415 -1891,393.676 -1891,393.413 -1872.943,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0A5AD8" points="-1873.376,415 -1891,393.939 -1891,393.676 -1873.16,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0A59D8" points="-1873.594,415 -1891,394.205 -1891,393.939 -1873.376,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0958D8" points="-1873.811,415 -1891,394.47 -1891,394.205 -1873.594,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0957D8" points="-1874.025,415 -1891,394.735 -1891,394.47 -1873.811,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0956D8" points="-1874.24,415 -1891,395 -1891,394.735 -1874.025,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0955D8" points="-1874.458,415 -1891,395.265 -1891,395 -1874.24,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0854D8" points="-1874.676,415 -1891,395.529 -1891,395.265 -1874.458,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0853D8" points="-1874.891,415 -1891,395.793 -1891,395.529 -1874.676,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0852D8" points="-1875.108,415 -1891,396.058 -1891,395.793 -1874.891,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0851D8" points="-1875.324,415 -1891,396.323 -1891,396.058 -1875.108,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0750D8" points="-1875.54,415 -1891,396.588 -1891,396.323 -1875.324,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#074FD8" points="-1875.757,415 -1891,396.851 -1891,396.588 -1875.54,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#074ED8" points="-1875.974,415 -1891,397.115 -1891,396.851 -1875.757,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#074DD8" points="-1876.189,415 -1891,397.38 -1891,397.115 -1875.974,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#064CD8" points="-1876.406,415 -1891,397.643 -1891,397.38 -1876.189,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#064BD8" points="-1876.624,415 -1891,397.907 -1891,397.643 -1876.406,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#064AD8" points="-1876.839,415 -1891,398.173 -1891,397.907 -1876.624,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0649D8" points="-1877.056,415 -1891,398.438 -1891,398.173 -1876.839,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0548D8" points="-1877.272,415 -1891,398.703 -1891,398.438 -1877.056,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0547D8" points="-1877.488,415 -1891,398.968 -1891,398.703 -1877.272,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0546D8" points="-1877.705,415 -1891,399.232 -1891,398.968 -1877.488,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0545D8" points="-1877.923,415 -1891,399.496 -1891,399.232 -1877.705,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0544D8" points="-1878.139,415 -1891,399.761 -1891,399.496 -1877.923,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0443D8" points="-1878.354,415 -1891,400.025 -1891,399.761 -1878.139,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0442D8" points="-1878.572,415 -1891,400.29 -1891,400.025 -1878.354,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0441D8" points="-1878.787,415 -1891,400.554 -1891,400.29 -1878.572,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0440D8" points="-1879.005,415 -1891,400.818 -1891,400.554 -1878.787,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#033FD8" points="-1879.221,415 -1891,401.083 -1891,400.818 -1879.005,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#033ED8" points="-1879.438,415 -1891,401.348 -1891,401.083 -1879.221,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#033DD8" points="-1879.654,415 -1891,401.613 -1891,401.348 -1879.438,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#033CD8" points="-1879.87,415 -1891,401.876 -1891,401.613 -1879.654,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#023BD8" points="-1880.087,415 -1891,402.141 -1891,401.876 -1879.87,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#023AD8" points="-1880.303,415 -1891,402.405 -1891,402.141 -1880.087,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0239D8" points="-1880.521,415 -1891,402.671 -1891,402.405 -1880.303,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0238D8" points="-1880.735,415 -1891,402.936 -1891,402.671 -1880.521,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0137D8" points="-1880.952,415 -1891,403.199 -1891,402.936 -1880.735,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0136D8" points="-1881.168,415 -1891,403.464 -1891,403.199 -1880.952,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0135D8" points="-1881.385,415 -1891,403.729 -1891,403.464 -1881.168,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0134D8" points="-1881.601,415 -1891,403.993 -1891,403.729 -1881.385,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0033D8" points="-1881.817,415 -1891,404.257 -1891,403.993 -1881.601,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0032D8" points="-1882.035,415 -1891,404.521 -1891,404.257 -1881.817,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0031D8" points="-1882.251,415 -1891,404.786 -1891,404.521 -1882.035,415 		"/>
		<polygon clip-path="url(#SVGID_5_)" fill="#0031D8" points="-1891,404.786 -1882.251,415 -1891,415 		"/>
	</g>
</g>
			<g>

				<g>
		<g>
			<g>
				<path fill="#404552" d="M-2595.647,577.067v58.178h-6.541v-58.178H-2595.647z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2574.734,619.41v9.639h20.396v6.196h-27.281v-38.814h27.109v22.979H-2574.734z M-2574.734,613.041
					h13.942V602.8h-13.942V613.041z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2519.826,612.353v-9.725h-20.397v-6.197h27.282v38.814h-27.11v-22.893H-2519.826z M-2519.826,618.635
					h-13.856v10.242h13.856V618.635z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2482.476,602.8l-9.467,0.086v32.359h-6.885v-38.814h22.893v14.114h-6.541V602.8z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2442.457,592.215h-6.282v-6.283h18.676v6.283h-5.853v36.662h6.282v6.368h-18.589v-6.368h5.766V592.215
					z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2397.79,635.245v-32.617h-13.855v32.617h-6.541v-38.814h27.281v38.814H-2397.79z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2355.964,596.431v-19.363h6.455v58.178h-27.282v-38.814H-2355.964z M-2355.964,628.877v-26.249
					h-13.941v26.163L-2355.964,628.877z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2308.112,596.431v38.814h-27.282v-38.814h6.541v32.618h13.855v-32.618H-2308.112z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2273.602,628.791v-7.315l-20.396-4.733v-20.312h27.281v14.114h-6.454v-7.659h-13.942v7.659
					l20.225,4.734v19.88l-27.109,0.086v-9.725h6.54v3.271H-2273.602z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2228.849,635.245h-15.836v-32.617h-7.918v-6.197h7.918v-11.445h6.541v11.445h9.295v6.197h-9.295
					v26.421h9.295V635.245z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2198.383,602.8l-9.467,0.086v32.359h-6.885v-38.814h22.893v14.114h-6.541V602.8z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2172.994,577.067v11.446h-6.541v-11.446H-2172.994z M-2172.994,596.431v38.814h-6.541v-38.728
					L-2172.994,596.431z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2152.081,619.41v9.639h20.396v6.196h-27.281v-38.814h27.109v22.979H-2152.081z M-2152.081,613.041
					h13.942V602.8h-13.942V613.041z"/>
			</g>
			<g>
				<path fill="#404552" d="M-2097.173,628.791v-7.315l-20.397-4.733v-20.312h27.282v14.114h-6.455v-7.659h-13.941v7.659
					l20.225,4.734v19.88l-27.11,0.086v-9.725h6.541v3.271H-2097.173z"/>
			</g>
		</g>
	</g>
</g>
</svg>;

export default logo;